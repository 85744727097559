import React, { useState } from 'react'
import Form from '../components/Form'
import Footer from '../components/Footer'
import Title from '../components/UI/Title'
import { FiGift } from 'react-icons/fi'
import Button from '../components/UI/Button'
import I18n from '../components/UI/I18n'
import Photo from '../components/UI/Photo'
import Menu from '../components/UI/Menu'
import Info from '../components/UI/Info'
import Carrousel from '../components/UI/Carrousel'
import HelpCenter from '../components/HelpCenter'
import { Helmet } from 'react-helmet'

const scrollForm = () => {
  window.scrollTo(0, document.getElementById('form').offsetTop)
}

export default function Home() {
  return ( 
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Lalalab x Wizito Photo Booth</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        {/* verify Pinterest */}
        <meta name="p:domain_verify" content="3b8fd1835c98628d3884e1dea41d27fb" />
        <meta name="facebook-domain-verification" content="79m961bq8z66qambg14j4lhdplva7v" />
      </Helmet>
      {/* <div className="recoleta text-sm lg:text-xl bg-black w-full text-white text-center leading-tight py-3 px-1 lg:px-3">🔥 Le Black Friday est là ! 🔥 Louez votre photobooth pour seulement 269€ (au lieu de 369€).<br/>🎉 Utilisez le code BLACKFRIDAY100 pour profiter de cette offre exclusive, valable jusqu'au 2 décembre ! ⏳</div> */}
      <div className="mt-4">
        <img src="/logo-pink.png" className="w-40 lg:w-56 my-8 mx-auto" />
        <div className="max-w-screen-lg mx-auto px-4 flex justify-center flex-col lg:flex-row items-stretch lg:mb-8">
          <div className="flex-shrink-0 w-full lg:w-1/2 mr-4">
            <div
              className="hidden lg:block w-full h-full bg-cover bg-center border-15 border-white mr-6"
              style={{ backgroundImage: 'url(/image1.jpg)' }} />
          </div>
          <div className="ml-4 flex flex-col justify-start items-start">
            <Title className="lg:text-left my-4" variant="extra-large">
              Une borne photo pour votre événement ?
            </Title>
            <p>Capturez des souvenirs inoubliables !</p>
            <p>Plongez dans l'expérience unique du photobooth et transformez votre journée en une galerie vivante de sourires et de complicité.</p>
            <p>Le photobooth Made In France de Wizito vous permet d’imprimer 600 photos en haute qualité lors de vos événements (mariage, anniversaire, baptême, baby shower...). Nos options de personnalisation vous permettront de créer des clichés inoubliables en adéquation avec l’ambiance de votre événement.</p>
            <div className="text-3xl recoleta my-4">à partir de <strong>369 €</strong></div>
            <div onClick={scrollForm}><Button className="mx-auto lg:mx-0">Vérifier les disponibilités</Button></div>
          </div>
        </div>
        <div className="py-8 px-2 text-center">
          <div id="form" className="absolute -mt-40" />
          <Form />
        </div>
        <div className="w-full pt-5 pb-46">
          <Title className="text-center" variant="extra-large">Notre offre comprend</Title>
          <Carrousel className="items-stretch">
            {[
              { description: '<strong>Imprimante à sublimation thermique</strong> pour des impressions de qualité labo', image: '/lalalab/impression-uv.png' },
              { description: '<strong>Récupération gratuite dans nos locaux</strong> (Paris ou Lyon)', image: '/lalalab/colis-retourne.png' },
              { description: '<strong>Option pour livraison</strong> UPS à domicile <small>(France, Belgique)</small>', image: '/lalalab/livraison-rose.png' },
              { description: 'Photobooth avec <strong>appareil photo reflex Canon</strong> ', image: '/lalalab/sublimez-vos-photos.png' },
              { description: '<strong>600 impressions</strong>  photos au format 7,5x10 cm <small>(Chaque photo s’imprime en 2, 4 ou 6 exemplaires)</small>', image: '/lalalab/prints.png' },
              { description: '<strong>Personnalisez le cadre</strong> de votre photo imprimée', image: '/lalalab/designs-uniques.png' },
              { description: 'Photos mises à disposition dans une <strong>galerie en ligne</strong>', image: '/lalalab/web.png' },
              { description: 'Photobooth et logiciel entièrement <strong>créés et développés par nos soins</strong>', image: '/lalalab/france.png' },
            ].map(i => (
              <div key={i.description} className="flex flex-col justify-center select-none items-center w-40 lg:w-64 m-2 lg:m-3 text-center bg-white px-2 py-4 lg:px-3 leading-tight lg:leading-normal">
                <img src={i.image} className="relative flex justify-center items-center select-none h-16 z-10 pointer-events-none" />
                <p className="text-lg lg:text-xl leading-tight mt-2 text-gray-600 recoleta" dangerouslySetInnerHTML={{ __html: i.description }} />
              </div>
            ))}
          </Carrousel>
        </div>
        <div className="relative max-w-screen-lg mx-auto flex flex-col lg:flex-row justify-center flex-wrap mb-12 pt-4 text-white">
          <img src="/image8.jpg" className="border-15 border-white w-full lg:w-1/2+m my-2 lg:m-4" />
          <img src="/image2.jpg" className="border-15 border-white w-full lg:w-1/2+m my-2 lg:m-4" />
          <img src="/image3.jpg" className="border-15 border-white w-full lg:w-1/2+m my-2 lg:m-4" />
        </div>
      </div>
    </>
  )
}
